<template>
  <div style="margin-bottom:2rem;">
    <div class="title1">
      <p class="title_top">LISI NEWS</p>
      <p class="title_content">历思动态</p>
    </div>
    <div style="margin-bottom:2rem;">
      <div @click="go(item.id)" :class="'box_shadow col7-3 flex center wrap '+(index!=(data.length-1)?'item':'')"
           v-for="(item,index) in data" :key="item.id">
        <div class="col3 flex center" style="
                    max-height: 222px;
                    height: 70%;
                    padding: 20px 0px;
                    min-height: 100px;">
          <img style="
                                width: 100%;
                                max-width: 285px;
                                height: inherit;
                                max-height: inherit;
                                min-height: inherit;" v-lazy="require('../../assets/news/ls/'+item.url)">
        </div>
        <div class="col7 flex center" style="text-align:left;min-height:273px">
          <div class="col9" style="font-size:0.9rem;margin:0 auto">
            <div class="space_content" style="font-size:1rem;margin-bottom: 0px;font-weight:bold;margin-top: 10px;">
              {{ item.title }}
            </div>
            <div class="space_content" style="font-size:1rem;text-align:right;color:#a7a7a7">
              {{ item.time }}
            </div>
            <div class="space_content" :key="index" v-for="(content,index) in item.content"
                 :style="(index!=(content.length-1))?'margin-bottom: 10px;':''">
              {{ content }}
            </div>
          </div>

        </div>

      </div>
    </div>
    <More></More>
  </div>
</template>
<script>
import More from "@/components/More.vue"

export default {
  components: {More},
  data() {
    return {
      data: [
        {
          id: 1,
          url: '1-1.png',
          time: '2021-11-04',
          title: '历思战略合作单位——青岛中院领导莅临福建历思司法鉴定所调研指导！',
          content: ['10月27日，历思战略合作单位——青岛市中级人民法院领导到福建历思司法鉴定所进行调研指导。座谈会上，历思负责人介绍了2020年以来受理的青岛法院司法鉴定案件的概况。双方就司法鉴定案件中存在的难点问题及下一步更深入的合作进行交流讨论...']
        },
        {
          id: 2,
          url: '2-1.png',
          time: '2021-11-04',
          title: '庆贺！我所曾建晟主任当选厦门市司法鉴定协会监事长',
          content: ['10月29日，厦门市司法鉴定协会第五届会员代表大会在市司法局召开。大会选举产生了新一届理事会成员和新一届领导班子，其中，福建历思司法鉴定所主任曾建晟当选为协会监事长。...']
        },
        {
          id: 3,
          url: '3-1.png',
          time: '2021-11-29',
          title: '省创新研究院中科育成中心领导莅临历思调研',
          content: ['11月22日，福建省创新研究院中科育成中心主任冯先伟一行莅临厦门历思科技服务有限公司（以下简称历思）开展工作调研，了解历思发展规划和技术需求。',
            '历思领导向冯主任介绍了公司的基本情况。历思是一家拥有多专业物证优势技术的国家级高新技术企业，目前历思旗下有3家司法鉴定所、1家检测集团公司、1家生物科技公司、1家价值评估公司。历思自主研发的多类产品已申报并获批多项国内、国际发明专利...']
        },
        {
          id: 4,
          url: '0-1.png',
          time: '2016.12.09',
          title: '历思承办案件入选福建省高级人民法院2015年十大典型案件',
          content: ['在2016年福建省高级人民法院公布的2015年十大典型案件中，福建历思司法鉴定所受厦门市中级人民法院委托进行文书形成时间等司法鉴定，鉴定意见成为案件关键证据，有力支持了司法审判工作...']
        },
        {
          id: 5,
          url: '0-1.png',
          time: '2016.12.09',
          title: '历思入选环境保护部环境损害鉴定推荐机构名录',
          content: ['在2016年环境保护部办公厅环办政法[2016]10号文件关于印发《环境损害鉴定评估推荐机构名录（第二批）》的通知中，福建历思司法鉴定所联合福建省环境科学研究院进入环保部环境损害鉴定推荐机构名录。']
        },
        {
          id: 6,
          url: '0-1.png',
          time: '2016.12.09',
          title: '秦天宝等：我国土壤污染防治立法探究',
          content: ['随着土壤污染防治专门立法的启动，我国土壤污染防治的相关制度也将逐步建立，这对于指导土壤污染防治的实践工作具有重要意义。然而在立法过程中各方争议较大且亟待解决的几个关键问题有必要深入探究...']
        },
        {
          id: 7,
          url: '7-1.png',
          time: '2017.03.16',
          title: '历思通过检验检测机构资质认定（CMA认证）',
          content: ['2017年2月，历思正式获得由福建省质量技术监督局颁发的检验检测机构资质认定（CMA认证）证书，证书编号：171307030017。关于CMA认证：中国计量认证简称“CMA”...']
        },
        {
          id: 8,
          url: '0-1.png',
          time: '2017.03.24',
          title: '历思受邀为党员律师讲解司法鉴定实务专题学习活动',
          content: ['2017年3月24日下午，北京盈科（厦门）律师事务所党支部组织2017年度第一期党员业务学习活动。特邀来自福建历思司法鉴定所曾志胤主任、法医鉴定室曾建晟主任共同为党员律师讲解司法鉴定实务...']
        },
        {
          id: 10,
          url: '10-1.png',
          time: '2018.01.14',
          title: '笔迹专家秀绝技 上电视准确“破案”',
          content: ['厦门晚报报道：（记者 王东城）2018 年.1月.13日在湖南卫视《快乐大本营》上，厦门笔迹学会终身荣誉会长金一贵和学会常务副会长兼秘书长许桂月受邀登台亮相，以字“破案”，找出了案件“主谋”。...']
        },
        {
          id: 11,
          url: '0-1.png',
          time: '2018.03.22',
          title: '科学仪器为您揭开古玩艺术品的真伪',
          content: ['海峡导报报道：导报讯（记者 吴舒远）2018年3月21日下午，由海峡导报、福建省古玩商会厦门分会、北京垣钧文物鉴定技术研究院联合举办的鉴宝艺术沙龙在厦门顺天达拍卖有限公司举行...']
        },
        {
          id: 12,
          url: '0-1.png',
          time: '2018.04.27',
          title: '厉害了，国内首个环境损害鉴定评估标准在福建省开始实施了！',
          content: ['面对当今资源约束趋紧、环境污染严重、生态系统退化的严峻形势，必须树立尊重自然、顺应自然、保护自然的生态文明理念，走可持续发展道路。环保问题作为与人民生活息息相关的民生问题...']
        },
        {
          id: 13,
          url: '13-1.png',
          time: '2018.06.22',
          title: '涉嫌违法污染环境 — 厦门海沧非法炼油厂被端！',
          content: ['福建省厦门市环保局海沧分局执法人员在日常巡查时，途经天竺山附近郊区时，闻到空气中混杂着刺鼻的油料味，出于职业敏感，执法人员根据刺鼻气味情况分头展开排查，经过近1小时摸排...']
        },
        {
          id: 14,
          url: '14-1.png',
          time: '2018.07.20',
          title: '厦门公安局集美分局王局长一行莅临福建历思司法鉴定所调研',
          content: ['近日，厦门市公安局集美分局局长王嘉兴、副局长王光锋、刑侦大队副大队长薛宝强等领导莅临福建历思司法鉴定所（以下简称“历思”）进行调研,历思目前已获得的司法鉴定资质有17大项...']
        },
        {
          id: 15,
          url: '15-1.png',
          time: '2018.08.13',
          title: '历思案件被评选上了中国法律服务网，被司法行政（法律服务）收录为典型案例啦！',
          content: ['柯某驾驶的重型特殊结构货车在某市大道的灯杆路段中，与王某驾驶的两轮普通摩托车发生交通事故，造成车损及王某当场死亡的损害后果。当地警方特委托福建历思司法鉴定所对王某的尸体...']
        },
        {
          id: 16,
          url: '16-1.png',
          time: '2018.09.13',
          title: '历思利用毛发检测，高新技术打破海南禁毒“滞案”僵局！',
          content: ['海南拥有美丽的生态环境，是全国最大的经济特区和唯一的省域国际旅游岛。然而，受国际毒潮泛滥和国内多种因素的影响，毒品问题一度成为影响当地社会安定和民生安康的源头性...']
        },
        {
          id: 17,
          url: '17-1.png',
          time: '2018.09.21',
          title: '协会状告养猪人索赔150万元，历思助力厦门首例环境公益诉讼案！',
          content: ['近日，一场特殊的宣判在厦门中院举行。原告是福建省环保志愿者协会，被告是厦门一名男子叶某，叶某擅自开设大规模养殖场，造成大面积环境污染，被判向政府财政专户支付环境污染赔偿款91.75万元...']
        },
        {
          id: 18,
          url: '18-1.png',
          time: '2018.12.07',
          title: '厦门市公安局许标旗副局长一行莅临考察福建历思司法鉴定所',
          content: [' 近日，厦门市公安局许标旗副局长、湖里禁毒大队黄思强大队长、湖里分局技术科蔡荣泉科长等领导莅临福建历思司法鉴定所（以下简称历思）进行考察。 福建历思司法鉴定所目前已获得的司法鉴定资质有12大类，...']
        },
        {
          id: 19,
          url: '19-1.png',
          time: '2018.12.07',
          title: '厦门市司法局叶局长一行调研指导福建历思司法鉴定所',
          content: [' 近日，厦门市司法局党组书记叶局长、办公室林副主任、司法鉴定仲裁管理处黄处长等领导莅临我所进行调研，参观了历思物证中心实验室并听取了福建历思司法鉴定所发展状况与环境资质申报情况汇报...']
        },
        {
          id: 20,
          url: '20-1.png',
          time: '2019.01.08',
          title: '厦门市湖里区林重阳区长一行莅临考察福建历思司法鉴定所',
          content: ['近日，厦门市湖里区林重阳区长、厦门市公安局许标旗副局长、分局党委委员办公室王康主任等领导莅临福建历思司法鉴定所（以下简称历思）进行考察。林区长一行参观了历思物证中心实验室，...']
        },
        {
          id: 21,
          url: '21-1.png',
          time: '2019.01.11',
          title: '典型案例来了！高处坠物工伤事故的伤残等级鉴定',
          content: ['还记得不久前上热搜的“高空坠狗”事件吗？ 广州的张女士被突然从高楼上的大狗砸中，狗主人身份成谜，狗也消失无踪，她却倒地昏迷不起，后经救治，负债累累，但仍高位截瘫。直至目前...']
        },
        {
          id: 22,
          url: '22-1.png',
          time: '2019.01.24',
          title: '朱墨时序鉴真伪，却是老赖“挖坑”埋了自己！',
          content: [' 案件详情： 卢某在当地的石材厂从事开叉车的工作，某日，他以要买二手叉车缺少资金为由向杜某借款15万元，借款期限为4个月，月利率5%，并出具一份借款收条给杜某。当借款期限届满后...']
        },
        {
          id: 23,
          url: '23-1.png',
          time: '2019.04.01',
          title: '号外，号外，我们又和一所高校握手了！',
          content: ['地处滨海之滨，山明兮水秀，有着“中国最美校园”的厦大，红瓦白墙，绿草如茵，白鹭成群，“穿西装，戴斗笠”的嘉庚建筑，中西合璧，风格独树一帜，这就是近百年院校——“厦门大学"近日...']
        },
        {
          id: 24,
          url: '24-1.png',
          time: '2019.06.24',
          title: '庆贺，全项7大类一次性通过！根据环境损害鉴定新资质要求，历思顺利进行重新审核登记！',
          content: ['近日，在司法部“商两高”的背景下，环境损害鉴定归入“四大类”，基于环境损害鉴定资质的新要求，历思顺利通过专家评审，原环境物证司法鉴定重新登记为环境损害鉴定7项资质...']
        },
        {
          id: 25,
          url: '25-1.png',
          time: '2019.07.10',
          title: '庆贺！历思物证科技产研体系成员企业——堃晟，通过了“CMA”授权颁发的珠宝玉石及贵金属的能力资格认证！A”授权颁发的珠宝玉石及贵金属的能力资格认证！',
          content: ['近日，历思物证科技产研体系成员企业——厦门堃晟检测技术有限公司（以下简称“堃晟”）顺利通过了CMA （中国国家质量检测部门）授权颁发的珠宝玉石及贵金属的能力资格认证...']
        },
        {
          id: 26,
          url: '26-1.png',
          time: '2019.11.01',
          title: '武汉市司法局关太兵局长一行莅临福建历思司法鉴定所调研！',
          content: ['2019年10月31日，武汉市司法局关太兵局长、聂德宗副局长等领导莅临福建历思司法鉴定所（以下简称“历思”）进行调研指导。 关太兵局长一行参观了历思物证中心实验室并...']
        },
        {
          id: 27,
          url: '27-1.png',
          time: '2019.12.08',
          title: '庆贺！福建历思司法鉴定所党支部与厦门市税务局第二稽查局检查三科党支部签订党建共建协议！',
          content: ['贯彻党的十九大精神，加强基层党支部建设，建立税企合作新机制，促进税企融合发展，2019年11月28日，福建历思司法鉴定所党支部（以下简称“历思”）与厦门市税务局第二...']
        },
        {
          id: 28,
          url: '28-1.png',
          time: '2020.04.29',
          title: '庆贺！厦门CA与历思科技正式建立战略合作伙伴关系！',
          content: ['2020年4月23日，厦门数字证书管理有限公司（以下简称“厦门CA”）与厦门历思科技服务有限公司（以下简称”历思”）签署协议，正式建立战略合作伙伴关系。厦门CA是厦门...']
        },
        {
          id: 29,
          url: '29-1.png',
          time: '2020.05.09',
          title: '母亲节福利来了！珠宝玉石免费检测鉴定活动——3天！',
          content: ['母亲节到了，历思又来给大家送福利啦，只要大家在公众号后台，发送一句“母亲节快乐，我来历思领福利”的口号，就可领取一份免费检测，  鉴定珠宝玉石的福利哦！翡翠、玉器...']
        },
        {
          id: 30,
          url: '30-1.png',
          time: '2020.06.28',
          title: '快看，历思“智慧禁毒”黑科技，上电视台啦~',
          content: ['6.26国际禁毒日即将到来，历思一则“智慧禁毒”黑科技的新闻，登上福建省电视台新闻频道特别节目——《禁毒之战2020》，提前给大家进行了“热身”。如今的“智慧禁毒”已渗透到禁毒...']
        },
        {
          id: 31,
          url: '31-1.png',
          time: '2020.08.18',
          title: '庆贺！集美大学信息工程学院与历思“牵手”成功！',
          content: ['近日，集美大学信息工程学院和厦门历思科技有限公司（以下简称历思）正式合作共建智慧物证工程技术中心暨联合实验室（以下简称共建中心）！ 集美大学信息工程学院, 集美大学信...']
        },
        {
          id: 32,
          url: '32-1.png',
          time: '2020.10.14',
          title: '【攻略】条条线路通历思~',
          content: ['历思附近住宿推荐：1、厦门柏曼酒店（湖里店),价格：300-900元,地址：湖里区湖里大道24号太阳岛大厦(湖里大道与宜宾北路交叉口),  电话：0592-8026788...']
        },
        {
          id: 33,
          url: '33-1.png',
          time: '2020.11.13',
          title: '中国药科大学狄斌教授一行莅临历思就“污水验毒”等技术进行研讨交流！',
          content: ['近日，中国药科大学狄斌教授、苏梦翔副教授等一行莅临福建历思司法鉴定所（以下简称“历思”）进行研讨交流。座谈会上，双方围绕毒品检测技术和仪器设备的科学化...']
        },
        {
          id: 34,
          url: '34-1.png',
          time: '2020.12.03',
          title: '【官宣】“污水验毒”专用智慧采样器，硬核上线啦~',
          content: ['好消息！厦门历思科技服务有限公司 旗下的厦门堃铭生物技术有限公司又出了一“硬核设备” “污水验毒”专用智慧采样器——智慧水质采样器 LS-WS20OF01上线啦！...']
        },
        {
          id: 35,
          url: '35-1.png',
          time: '2020.12.03',
          title: '全国首例！历思助力技术调查官参审污染环境案件，宣判啦~',
          content: ['近日，福建某法院对被告人黄某污染环境罪一案依法公开开庭审理并当庭宣判。 两名生态环境技术调查官首次出席参加庭审，成为全国首起 在生态环境诉讼中引入技...']
        },
        {
          id: 36,
          url: '36-1.png',
          time: '2020.12.03',
          title: '浙江省禁毒总队、福建省禁毒总队领导一行莅临历思就“污水验毒”等技术进行考察交流！',
          content: ['近日，浙江省禁毒总队、福建省禁毒总队领导一行 莅临福建历思司法鉴定所（以下简称“历思”）考察交流城市污水监测、毒情监测预警体系建设等工作。浙江省禁毒总队...']
        },
        {
          id: 37,
          url: '37-1.png',
          time: '2021.01.08',
          title: '厦门市税务局稽查局办公室和检查三科党支部与历思共建学习交流会！',
          content: ['近日，厦门市税务局稽查局办公室和检查三科党支部与历思共建学习交流会。会议由历思党支部书记林巧尔主持，历思全体党员以及稽查局办检党支部全体党员参加本次交流会...']
        },
        {
          id: 38,
          url: '38-1.png',
          time: '2021.03.25',
          title: '堃晟入选福建省污染地块调查评估、治理修复单位专业机构推荐名录',
          content: ['近日，《福建省污染地块调查评估、治理修复单位专业机构推荐名录（2021年度）》已经发布。历思物证科技产研体系成员企业—— 厦门堃晟检测技术有限公司...']
        },
        {
          id: 39,
          url: '39-1.png',
          time: '2021.03.25',
          title: '喜讯！热烈祝贺历思科技通过高新技术企业认定！',
          content: ['热烈祝贺厦门历思科技服务有限公司（以下简称“历思”）通过了“国家高新技术企业”认定，由此正式迈入国家高新技术企业行列。本次被评定为国家高新技术企业...']
        },
      ]
    }
  },
  methods: {
    go(id) {
      let pathStr = '/news/lsnews/' + id
      this.$store.commit('set_path', {path: pathStr, query: {}})
    }
  }
}
</script>
<style scoped>
.item {
  border-bottom: 1px solid #dddddd;
  margin-bottom: 2rem;
}
</style>
