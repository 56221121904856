<template>
    <div>
        <el-button @click="more" icon="el-icon-circle-plus-outline">查看更多</el-button>
    </div>
</template>
<script>
export default {
    data(){
        return{
        }
    },
    methods:{
        more(){
            this.$emit('more')
        }
    }
}
</script>
<style scoped src="@/common.css"></style>
<style scoped>
</style>